import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { sleep, startLoading, endLoading, dateFormat, avgScore, getnextMonthdate,baiduSearch } from "@/utils/function";
import API from '@/api/teacher/project/project';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import {
  domainUrl,
  projectMarkCoursePageSize,
  projectVistualCoursePageSize,
  projectReportCoursePageSize,
  uploadFilePath,
} from "@/utils/const";

// 引入基本模板
import * as echarts from 'echarts';
// 引入饼图组件
require('echarts/lib/chart/pie');
// 引入柱状图组件
require("echarts/lib/chart/bar");
// 引入提示框和title组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
require("echarts/lib/component/legend");
//百度地图
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import ReportRepeat from "../../../../components/ReportRepeat.vue";
// 弹窗组件
// import Tips from "../../../../components/tips.vue";

export default {
  name: "other",
  inject: ['reload'],
  components: {
    BaiduMap,
    ReportRepeat
    // Tips
  },
  data() {
    return {
      // 项目是否可拖拽排序
      sortAbled: false,
      project_name: '',
      is_show_bind: '',
      createUserName: '',
      // 绑定课程列表
      projectCourseList: [],
      scorepersonalIdOrderBy: 'asc',
      scoreexamScoreOrderBy: 'asc',
      scoreexpScoreOrderBy: 'asc',
      scoreoptScoreOrderBy: 'asc',
      scorereportScoreOrderBy: 'asc',
      scorefinalScoreOrderBy: 'asc',
      reporttimeOrderBy: 'asc',
      reportscoreOrderBy: 'asc',
      scoreOrderBy: 'asc',
      personalIdOrderBy: 'asc',
      report_student_name: '',
      vistual_student_name: '',
      mark_student_name: '',
      vistual_exp_id_copy: '',
      vistual_exp_name: '',
      rightShowTotalNum: 0,
      Reviewed: 0,
      projectName_complete: '',
      project_id_complete: '',
      isRecover: false,
      isShow: true,
      exam_paper_name: '',
      is_create: false,
      class_name: -1,
      project_typeList: [
        {
          id: -1,
          name: '全部项目',
          icon: 'http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-09-02-14-47-56_1466054.png'
        },
        {
          id: -2,
          name: '我的项目',
          icon: 'http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-09-02-14-59-45_1503735.png'
        },
        {
          id: -3,
          name: '共享项目',
          icon: 'http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-09-02-15-00-08_5874101.png'
        },
        {
          id: 0,
          name: '其他项目',
          icon: 'http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-09-02-15-00-25_8901005.png'
        },
      ],
      reporttemplateId: '',
      reportsubmitlimitTimeList: [
        {
          id: 0,
          name: '一天'
        },
        {
          id: 1,
          name: '二天'
        },
        {
          id: 2,
          name: '三天'
        },
      ],
      // 报告状态
      report_status: 0,
      // 班级名称
      report_group_name: '',
      // 报告状态列表
      report_status_id_list: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '待批'
        }
      ],
      // 报告状态id
      report_status_id: '全部',
      cidType: "1",
      classtimeRander: [dateFormat('YYYY-mm-dd HH:MM', new Date()), getnextMonthdate()],
      // 虚拟数据列表
      expdataList: [],
      deductList: [],
      // 虚拟实验操作记录弹窗标题
      expDialogTitle: '',
      expDialogVisible: false,
      expoptList: [],
      useAlling: false,
      useScoreAlling: false,
      messageTip: '',
      messageCode: '',
      messagevirtualTip: '',
      messagevirtualCode: '',
      radio: 1,
      // 操作扣分类型列表
      optTypeList: [
        { id: 0, name: '扣分' },
        { id: 1, name: '加分' }
      ],
      // 操作类型（0-扣分，1-加分）
      optSign: 0,
      downreportId: '',
      showdownreportDialog: false,
      deleteClassShowSign: false,
      activeName: "",
      addProjectActive: "one",
      // 项目列表
      projectList: [],
      // 课程列表
      classList: [],
      // 课程id
      class_id: -1,
      deleteProjectList: [],
      deleteNum: 0,
      currentProject_id: 0,
      projectIndex: -1,
      projectFilePath: "",
      projectFileList: [],
      rightContentShow: true,
      // =====================================================
      // 右侧tab页  设置数据
      uploadFilePath: process.env.VUE_APP_DOMAIN_URL + uploadFilePath,
      projectInfo: {},
      article_id: "",
      salve_uid: "",
      report_template_id: "",
      exam_paper_id: 0,
      articleList: [],
      togetherList: [],
      reportTemplateList: [],
      // 试卷列表
      examPaperList: [],
      // 坐标
      coordinate: "",
      // 百度地图弹窗标识
      baiduDialogVisible: false,
      // 地图显示中心
      baiduCenter: "大连",
      options: [],
      together_id: "",
      list: [],
      loading: false,
      //同事列表
      togetherListSearch: [],
      // =====================================================
      // 右侧tab页  成绩数据
      // 群组下拉列表数据
      groupList: [],
      // 成绩表格数据
      studentList: [],
      mark_group_id: "",
      noReportSubmitNum: 0,
      noSubscribeNum: 0,
      markDialogVisible: false,
      // 成绩弹窗显示标识 import，export，subscribe，report，totalmark
      markDialogSign: "",
      expscoretype: 0,
      expscoreString: "",
      fileList: [],
      // 文件路径
      markFilePath: "",
      markPage: 1,
      markDataTotal: 0,
      markPageSize: projectMarkCoursePageSize,
      // =====================================================
      // 右侧tab页  权重数据
      // 权重信息
      weightInfo: {},
      weight_total: 0,
      weightDialogVisible: false,
      weightDialogSign: "",
      weightDialogWidth: "30%",
      weightDialogTitle: "",
      // 操作内容
      handleContent: "",
      handleScore: "",
      handle_id: "",
      // 操作列表
      handleList: [],
      weightHandleDialogVisible: false,
      weightHandleDialogTitle: "",
      weightHandleDialogSign: "",
      weightRadioNum: 0,
      weightCheckboxNum: 0,
      weightJudgeNum: 0,
      weightPackNum: 0,
      weightPassScore: 0,
      weightTestTime: 0,
      weightTestType: 0,
      weightLevelTitle: "",
      weightLevelDialogVisible: false,
      weightVirtualTest: 0,
      weightVirtualLanguage: 0,
      weightVirtualMode: 0,
      weightVirtualCid: 0,
      weightVirtualList: [],
      isSgin:"1",
      isCheat:"1",
      isCurrentDay:"1",
      isRepeat:"1",
      isCalc:"1",
      isLook:"1",

      // =======================================================
      // 设置  dialog 数据
      // dialog 显示标识
      dialogVisible: false,
      // 弹窗操作标识 'create','rename','delete','recover','delete_completely','add_toegther'
      handleSign: "",
      dialogTitle: "",
      projectName: "",
      project_id: "",
      // =====================================================
      // 右侧tab页  虚拟数据
      // 虚拟实验id
      vistual_exp_id: "",
      vistual_exp_list: [],
      vistual_group_id: "",
      vistual_group_list: [],
      vistual_data_list: [],
      vistual_nopaycount: 0,
      vistual_dialog: false,
      vistual_sign: "flush",
      vistual_expinfo: {},
      user_balance: 0,
      vistualPage: 1,
      vistualDataTotal: 0,
      // vistualPageSize: projectVistualCoursePageSize,
      vistualPageSize: 10,
      // =====================================================
      // 右侧tab页  报告数据
      report_group_id: 0,
      report_group_list: [],
      report_data_list: [],
      reportPage: 1,
      reportDataTotal: 0,
      reportPageSize: projectReportCoursePageSize,
      weightData: [],
      projectList2: [],
      // 项目上传url
      projectUploadUrl:
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/project/uploadImportProjectFile",
      // 成绩上传url
      scoreUploadUrl:
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/project/uploadExportMarkFile",
      unLockCost: 0,
      opteditSign: '',
      edithandleScore: '',
      edithandleContent: '',
      notReviewed: 0,
      notReportNum: '',
      examPaperNumber: '',
      reportsubmitallTime: '',
      // 报告提交时限实验结束后限时
      reportsubmitlimitTime: '',
      // 提交时限类型（1-无限制，2-统一时间截点，3-实验结束后限时）
      radio1: '1',
      radio2: '0',
      update_report_template_id: '',
      datatype: '0',
      classTime: '1',
      yuyan: '0',
      moshi: '1',
      flushType: '2',
      createexpList: [],
      createexpID: '',
      cidWeightTotal:100,
      // ---------------------------------------------------------------------
      // 项目成绩分析弹窗标识
      projectCourseDialogVisible:false,
      // 控制成绩分析弹窗显示内容
      projectShow:'1',
      projectTotal:[],
      projectExp:[],
      projectReport:[],
      projectOpt:[],
      projectTest:[],
      projectAvgTotal:[],
      projectHistogram: null,
      projectRadar: null ,
      projectExpEcharts:null,
      projectTestEcharts:null,
      projectOptEcharts:null,
      projectReportEcharts:null,
      groupName:'',
      projectNameTitle:'',
      loadingFlag: false,
      examList:[
        {
          exam_paper_id:undefined,
          weightRadioNum:0,
          weightCheckboxNum:0,
          weightJudgeNum:0,
          radio:0,
          checkbox:0,
          judge:0,
          pack:0,
        }
      ],
      showDuplicateDialogVisable:false,
      reportContentList:[],
      checkedAll:false,
      khzList:[],
      showReportKhzDialogVisable:false,
      is_show_report_score:"0",
      current_report_id:0,
      contrast_report_id:0,
      current_cid:0,
      reportDetailVisiable:false,
      currentReportRecordArray:"",
      contrastReportRecordArray:"",
      shareTogetherList:[],
      selectTogether:[],
      id:0,
      dialogShareVisible:false,
      shareProjectName: "",
      consumablesVisible:false,
      consumables:[
        {
          name:"",//名称
          num:"",// 数量
          unit:"",// 单位
          id:0,// id
        }
      ],
      is_open_exp:0,
      examScoreToOpen:"",

    };
  },

  created() 
  {
    const self = this;
    self.input = self.$router.currentRoute.query.search;
    self.page = Number(self.$router.currentRoute.query.page);
    self.project_id = self.$router.currentRoute.query.project_id;
    self.report_group_id = self.$router.currentRoute.query.report_group_id;
    self.report_status_id = self.$router.currentRoute.query.report_status_id ?? '全部';
    self.activeName = self.$router.currentRoute.query.activeName ?? '';
    self.class_id = self.$router.currentRoute.query.sign ?? -1;
    self.project_typeList.forEach((item) => {
      if (item.id == self.class_id) {
        self.class_id = item.name
        self.class_name = item.id
      }
    })
    self.getProjectList();
  },

  mounted() 
  {
    const self = this;
    self.rowDrop();
    if (self.getProjectList.length > 0 && self.project_id == '') {
      sleep(800).then(() => {
        self.updateCssStyle(self.projectList[0].id);
      });
    }
    self.list = this.togetherListSearch.map((item) => {
      return { value: `value:${item.id}`, label: `${item.name}` };
    });
  },

  methods: 
  {
    lockUserExp(e) 
    {
      console.log(e);
      let self = this;
      let str = "确认要解冻该同学吗?";
      if(e.is_lock == 0)
      {
        str = "确认要冻结该同学吗?";
      }
      if(e.buy_id == 0)
      {
        self.$Tips({
          // 消息提示内容
          message: '请先解锁实验！',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 消失时间
          displayTime: 1500
        });return;
      }
      // 请求参数
      let data = {
          buy_id : e.buy_id,
          is_lock: e.is_lock
      };
      // 删除请求
      self.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        nprogressStart();
        self.$Loading.show();
        self.axios
          .post("/index.php/project/lockUserExp",data)
          .then((result)=>{
            self.$Loading.hide();
            nprogressClose();
            if(result.data.code > 0)
            {
              // 重新获取数据
              self.getVistualList();
            }
            else
            {
                self.$Tips({
                // 消息提示内容
                message: result.data.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 消失时间
                displayTime: 1500
                });return;
            }
        })//*/
      }).catch(() => {
          console.log("点击了取消！");        
      });//*/
    },

    deleteConsumablesByIndex(index)
    {
      const self = this;
      console.log(index)
      self.consumables.splice(index,1);
      console.log(self.consumables)
    },

    addConsumablesLine()
    {
      const self = this;
      self.consumables.push({
        name:"",
        num:"",
        unit:"",
        id:0,
      });
    },

    dialogConsumablesFix()
    {
      const self = this;
      console.log(self.consumables);
      let sign = false;
      self.consumables.forEach((item) => {
        console.log(item)
        if(item.name == "" || item.num == "" || item.unit == "")
        {
          sign = true;
          self.$Tips({
            // 消息提示内容
            message: '请完善耗材信息',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 消失时间
            displayTime: 1500
          });return;
        }
      })
      if(!sign)
      {
        self.consumablesVisible = false;
        self.projectInfo.consumables = self.consumables;
      }
    },

    dialogShareFix()
    {
        const self = this;
        let datas = {
            together_id: self.selectTogether,
            id: self.id
        };
        self.axios
            .post("/index.php/project/shareProjectByID",datas)
            .then((result)=>{
            self.$Loading.hide();
            nprogressClose();
            if(result.data.code > 0)
            {
                self.dialogShareVisible = false;
            }
            else
            {
                self.$Tips({
                // 消息提示内容
                message: result.data.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 消失时间
                displayTime: 1500
                });return;
            }
        })//*/
    },

    handleShare(id,name)
    {
        const self = this;
        self.dialogShareVisible = true;
        self.id = id;
        self.shareProjectName = name;
    },

    calcUserById(row)
    {
      let self = this;
      let user_id = row.userid;
      let data = {
        project_id: self.project_id,
        group_id: self.mark_group_id,
        user_id:user_id
      };
      // 删除请求
      self.$confirm('确认要重新计算总分嘛?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        nprogressStart();
        self.$Loading.show();
        API.flushTotalScore(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.markChangeGroup();
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch(() => {
            nprogressClose();
            self.$Loading.hide();
          })
      }).catch(() => {
          console.log("系统出错了！")   
      });
    },
    handleClose()
    {
      this.reportDetailVisiable = false;
    },
    showReportDoubleDetail(row)
    {
      let self = this;
      // console.log(row);
      self.contrast_report_id = row.report_id;
      self.current_cid = row.question_id;
      let data = {
        contrast_report_id:self.contrast_report_id,
        current_cid:self.current_cid,
        current_report_id:self.current_report_id
      }
      self.axios
        .post("/index.php/project/showReportDoubleDetail",data)
        .then((result)=>{
          // console.log(result);
          if(result.data.code > 0)
          {
            self.reportDetailVisiable = true;
            self.currentReportRecordArray = result.data.currentArray;
            self.contrastReportRecordArray = result.data.contrastArray;
          }
          else
          {
            self.$Tips({
              // 消息提示内容
              message: result.data.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 消失时间
              displayTime: 1500
            });return;
          }
        })
    },

    exportExamDetail()
    {
      window.open("https://app.mools.net/backend/public/index.php/project/exportExamRecord?project_id="+this.project_id+"&group_id="+this.mark_group_id);
    },

    applyCHeckReportRepeat()
    {
      let self = this;
      let array = [];
      self.reportContentList?.forEach((item) => {
        if(item.check)
        {
          array.push(item.id);
        }
      })
      if(array.length == 0)
      {
        return self.$Tips({
          // 消息提示内容
          message: "请选择要查重的试题！",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 消失时间
          displayTime: 2000
        });
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      let data = {
        project_id: self.project_id,
        group_id: self.report_group_id,
        cidStr: array.join(','),
      }
      API.applyCHeckReportRepeat(data)
        .then((res) => {    
          // 开启loading
          nprogressClose();
          self.$Loading.hide(); 
          self.showDuplicateDialogVisable = false;
          self.checkedAll = false;     
          if (res.code > 0) 
          {
            return self.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 消失时间
              displayTime: 2000
            });
          }
          else
          {
            return self.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 消失时间
              displayTime: 2000
            });
          }
        })
    },

    getSSIM(row)
    {
      const self = this;
      self.current_report_id = row.report_id;
      if(row.khz == null || row.khz == "")
      {
        self.$Tips({
          // 消息提示内容
          message: '请点击查重按钮！',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 消失时间
          displayTime: 1500
        });return;
      }
      self.showReportKhzDialogVisable = true;
      self.khzList = JSON.parse(row.khz_result);
    },
    
    checkReportRepeat()
    {
      let self = this;
      let array = [];
      self.reportContentList?.forEach((item) => {
        if(item.check)
        {
          array.push(item.id);
        }
      })
      if(array.length == 0)
      {
        return self.$Tips({
          // 消息提示内容
          message: "请选择要查重的试题！",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 消失时间
          displayTime: 2000
        });
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      let data = {
        project_id: self.project_id,
        group_id: self.report_group_id,
        cidStr: array.join(','),
      }
      API.getReportRepeat(data)
        .then((res) => {    
          // 开启loading
          nprogressClose();
          self.$Loading.hide();      
          if (res.code > 0) 
          {
            self.showDuplicateDialogVisable = false;
            self.checkedAll = false;
            return self.$Tips({
              // 消息提示内容
              message: '查重任务创建成功！',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 消失时间
              displayTime: 2000
            });
          }
          else
          {
            self.showDuplicateDialogVisable = false;
            self.checkedAll = false;
            return self.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 消失时间
              displayTime: 2000
            });
          }
        })
    },
    toggleAll(check)
    {
      this.reportContentList?.forEach((item) => {
          item.check = check;
      })
    },
    showDuplicateDialog()
    {
      let self = this;
      let data = {
        project_id: self.project_id,
      }
      self.showDuplicateDialogVisable = true;
      API.getReportInfo(data)
        .then((res) => {
          self.reportContentList = res.list;
          if (res.code > 0) 
          {
            // return self.$Tips({
            //   // 消息提示内容
            //   message: '报告生成中，请稍后刷新网页查看报告！',
            //   // 消息提示类型（success-成功,warning-警告/失败）
            //   messageType: 'success',
            //   // 消失时间
            //   displayTime: 2000
            // });
          }
        })
    },

    async copyPdfLink()
    {
      let list = [];
      this.report_data_list.forEach(function(element) {
        if(element.pdf_path)
        {
          list.push(element.pdf_path);
        }
      })
      let str = list.join("\n");
      await navigator.clipboard.writeText(str)
      .then(() => {
        this.$Tips({
          // 消息提示内容
          message: '已复制到剪切板',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'success',
          // 消失时间
          displayTime: 1500
        });
      })
    },

    brushPoinrUpdate(str)
    {
      let self = this;
      let data = {
        str: str,
        student_group_id: self.vistual_group_id,
      }
      API.brushPoinrUpdate(data)
        .then((res) => {
          // 重新获取数据
          self.getVistualList();
        })
    },

    createReportPDF(row)
    {
      let data = {
        user_id: row.userid,
        project_id: row.project_id,
      }
      API.createReportPDF(data)
        .then((res) => {
          if (res.code > 0) 
          {
            // return self.$Tips({
            //   // 消息提示内容
            //   message: '报告生成中，请稍后刷新网页查看报告！',
            //   // 消息提示类型（success-成功,warning-警告/失败）
            //   messageType: 'success',
            //   // 消失时间
            //   displayTime: 2000
            // });
          }
        })
    },

    lookReportPdf(url)
    {
      window.open(url);
    },

    deleteExamByIndex(index)
    {
      const self = this;
      self.examList.splice(index,1);
    },

    addExamLine()
    {
      const self = this;
      let num = self.examPaperList.length;
      if(self.examList.length >= num)
      {
        this.$Tips({
          // 消息提示内容
          message:"您没有多余的试卷！",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          // 显示时间
          displayTime:1500
        })
        return;
      }
      self.examList.push(
        {
          // 试卷id
          exam_paper_id:undefined,
          // 单选题数量
          weightRadioNum:0,
          // 多选题数量
          weightCheckboxNum:0,
          // 判断题数量
          weightJudgeNum:0,
          // 单选题总数
          radio:0,
          // 多选题总数
          checkbox:0,
          // 判断题总数
          judge:0,
          // 填空题总数
          pack:0,
        }
      );
    },

    lockSort() 
    {
      this.sortAbled = !this.sortAbled;
      this.updateCssStyle(this.project_id)
    },

    scoresortChange(event) 
    {
      let self = this;
      if (event.prop == 'personal_id') 
      {
        self.scoreexpScoreOrderBy = '';
        self.scoreexamScoreOrderBy = '';
        self.scoreoptScoreOrderBy = '';
        self.scorereportScoreOrderBy = '';
        self.scorefinalScoreOrderBy = '';
        if (event.order == 'ascending') 
        {
          self.scorepersonalIdOrderBy = 'asc'
        }
        else 
        {
          self.scorepersonalIdOrderBy = 'desc'
        }
      }
      else if (event.prop == 'exp_score') 
      {
        self.scorepersonalIdOrderBy = '';
        self.scoreexamScoreOrderBy = '';
        self.scoreoptScoreOrderBy = '';
        self.scorereportScoreOrderBy = '';
        self.scorefinalScoreOrderBy = '';
        if (event.order == 'ascending') 
        {
          self.scoreexpScoreOrderBy = 'asc'
        }
        else 
        {
          self.scoreexpScoreOrderBy = 'desc'
        }
      }
      else if (event.prop == 'exam_score') 
      {
        self.scorepersonalIdOrderBy = '';
        self.scoreexpScoreOrderBy = '';
        self.scoreoptScoreOrderBy = '';
        self.scorereportScoreOrderBy = '';
        self.scorefinalScoreOrderBy = '';
        if (event.order == 'ascending') 
        {
          self.scoreexamScoreOrderBy = 'asc'
        }
        else 
        {
          self.scoreexamScoreOrderBy = 'desc'
        }
      }
      else if (event.prop == 'opt_score') 
      {
        self.scorepersonalIdOrderBy = '';
        self.scoreexpScoreOrderBy = '';
        self.scoreexamScoreOrderBy = '';
        self.scorereportScoreOrderBy = '';
        self.scorefinalScoreOrderBy = '';
        if (event.order == 'ascending') 
        {
          self.scoreoptScoreOrderBy = 'asc'
        }
        else 
        {
          self.scoreoptScoreOrderBy = 'desc'
        }
      }
      else if (event.prop == 'report_score') 
      {
        self.scorepersonalIdOrderBy = '';
        self.scoreexpScoreOrderBy = '';
        self.scoreexamScoreOrderBy = '';
        self.scoreoptScoreOrderBy = '';
        self.scorefinalScoreOrderBy = '';
        if (event.order == 'ascending') 
        {
          self.scorereportScoreOrderBy = 'asc'
        }
        else 
        {
          self.scorereportScoreOrderBy = 'desc'
        }
      }
      else if (event.prop == 'final_score') 
      {
        self.scorepersonalIdOrderBy = '';
        self.scoreexpScoreOrderBy = '';
        self.scoreexamScoreOrderBy = '';
        self.scoreoptScoreOrderBy = '';
        self.scorereportScoreOrderBy = '';
        if (event.order == 'ascending') 
        {
          self.scorefinalScoreOrderBy = 'asc'
        }
        else 
        {
          self.scorefinalScoreOrderBy = 'desc'
        }
      }
      self.getStudentMarkList();
    },

    reportsortChange(event) 
    {
      let self = this;
      // console.log(event);
      if (event.prop == 'report_score') 
      {
        self.reporttimeOrderBy = ''
        if (event.order == 'ascending') 
        {
          self.reportscoreOrderBy = 'asc'
        }
        else 
        {
          self.reportscoreOrderBy = 'desc'
        }
      }
      else if (event.prop == 'addtime') 
      {
        self.reportscoreOrderBy = ''
        if (event.order == 'ascending') 
        {
          self.reporttimeOrderBy = 'asc'
        }
        else 
        {
          self.reporttimeOrderBy = 'desc'
        }
      }
      self.getStudentReportList();
    },

    sortChange(event) 
    {
      let self = this;
      // console.log(event);
      if (event.prop == 'exp_score') 
      {
        self.personalIdOrderBy = ''
        if (event.order == 'ascending') 
        {
          self.scoreOrderBy = 'asc'
        }
        else 
        {
          self.scoreOrderBy = 'desc'
        }
      }
      else if (event.prop == 'personal_id') 
      {
        self.scoreOrderBy = ''
        if (event.order == 'ascending') 
        {
          self.personalIdOrderBy = 'asc'
        }
        else 
        {
          self.personalIdOrderBy = 'desc'
        }
      }
      self.getVistualList();
    },

    allTime() {
      this.classtimeRander = [];
    },

    limitTime() 
    {
      if (this.classtimeRander.length == 0) 
      {
        this.classtimeRander = [dateFormat('YYYY-mm-dd HH:MM', new Date()), getnextMonthdate()]
      }
    },

    noLimit() 
    {
      this.radio2 = "0";
      this.reportsubmitallTime = '';
      this.reportsubmitlimitTime = '';
    },

    timeALL() 
    {
      this.reportsubmitlimitTime = '';
    },

    timeLimit() 
    {
      this.reportsubmitallTime = '';
    },

    recoverTemplate() 
    {
      this.radio1 = "1",
        this.radio2 = "0",
        this.reportsubmitallTime = '';
      this.reportsubmitlimitTime = '';
    },

    scoreindexMethod(index) 
    {
      let currentPage = this.markPage - 0; // 当前页码
      let pageSize = this.markPageSize - 0; // 每页条数
      return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
    },

    virtualindexMethod(index) 
    {
      let currentPage = this.vistualPage - 0; // 当前页码
      let pageSize = this.vistualPageSize - 0; // 每页条数
      return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
    },

    reportindexMethod(index) 
    {
      let currentPage = this.reportPage - 0; // 当前页码
      let pageSize = this.reportPageSize - 0; // 每页条数
      return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
    },

    clearexpData() 
    {
      this.expdataList = [];
    },
    /**
     * @name: 恢复默认规则
     * @params: sign (true-均分，false-清空)
     */
    recoverRules() {
      let self = this;
      self.flushType = "2";
      self.yuyan = "0";
      self.moshi = "1";
      self.classTime = "1";
      self.datatype = "0";
      self.classtimeRander = [];
      self.cidType = "1"
      self.handleCid(true);
    },
    /**
     * @name: 清空/均分关卡配置
     * @params: sign (true-均分，false-清空)
     */
    handleCid(sign) 
    {
      this.weightVirtualList = avgScore(this.weightVirtualList.length, sign)
    },

    getExpDataRecordDataById(info) 
    {
      let self = this;
      let data = {
        id: info.id,
      }
      API.getExpDataRecordDataById(data)
        .then((res) => {
          if (res.code > 0) 
          {
            // self.expdataList = res.data;
             self.expdataList = Object.values(res.data); //对象转化为数组
            console.log(self.expdataList)
          }
        })
    },

    getcurrentActive(id = "", name = "", sign = "") 
    {
      let self = this;
      self.is_create = self.projectList[name].is_create;
      localStorage.setItem('is_create', self.is_create);
      self.studentList = [];
      self.mark_group_id = '';
      self.notReviewed = 0;
      self.Reviewed = 0;
      self.rightShowTotalNum = 0;
      self.vistual_exp_id = '';
      self.vistual_group_id = '';
      self.report_group_name = '';
      self.report_group_id = '';
      self.isShow = true;
      self.isRecover = false;
      self.rightContentShow = true;
      self.project_id = id;
      sleep(1000).then(() => {
        self.updateCssStyle(self.project_id);
      });
      if (sign == "delete") 
      {
        self.projectName = self.deleteProjectList[index].name;
        self.rightContentShow = false;
      }
      if (self.activeName == 'first') 
      {
        self.getProjectInfo(self.project_id, 0);
      }
      else if (self.activeName == "second") 
      {
        self.getProjectWeight();
      }
      else if (self.activeName == "third") 
      {
        self.getStudentMarkList();
      }
      else if (self.activeName == "fourth") 
      {
        self.getVistualList();
      }
      else if (self.activeName == "fifth") 
      {
        self.getStudentReportList();
      }
    },

    getStudentReportListSearch() 
    {
      this.reportPage = 1;
      this.getStudentReportList();
    },

    getVistualListSearch() 
    {
      this.vistualPage = 1;
      this.getVistualList();
    },

    getStudentMarkListSearch() 
    {
      this.markPage = 1;
      this.getStudentMarkList();
    },

    getExpDataDeductById(info) 
    {
      let self = this;
      let data = {
        id: info.id,
      }
      API.getExpDataDeductById(data)
        .then((res) => {
          if (res.code > 0) {
            self.deductList = res.data;
          }
        })
    },

    getVirtualRecordList(info) 
    {
      let self = this;
      self.expDialogTitle = `虚拟实验操作记录 / ${info.personal_id} ${info.name}`
      let data = {
        userid: info.userid,
        project_id: self.project_id
      }
      API.getVirtualRecordList(data)
        .then((res) => {
          if (res.code > 0) 
          {
            self.expoptList = res.data.lb
            self.expDialogVisible = true
          }
          else 
          {
            self.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button: [
                {
                  type: 1,
                  text: '知道了'
                }
              ],
            })
          }
        })
    },

    gogroupList() 
    {
      let self = this;
      self.$router.push({
        path: "/teacher/groupList",
      }).then(() => {
        self.reload()
      })
    },

    gocourseProject() 
    {
      let self = this;
      self.$router.push({
        path: "/teacher/course",
      }).then(() => {
        self.reload()
      })
    },

    showdownReport() 
    {
      let self = this;
      self.showdownreportDialog = true
    },

    pdfDownload() 
    {
      let self = this;
      if (!self.downreportId) 
      {
        // return Message.info('请选择群组')
        return self.$Tips({
          // 消息提示内容
          message: '请选择群组',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      window.open(process.env.VUE_APP_DOMAIN_URL + '/backend/public/index.php/project/pdfDownload?' +
        'project_id=' +
        self.project_id +
        '&group_id=' +
        self.downreportId)
      self.downreportId = '';
      self.showdownreportDialog = false;
    },

    clearEaxm() 
    {
      let self = this
      self.examPaperNumber.checkbox = 0;
      self.examPaperNumber.judge = 0;
      self.examPaperNumber.pack = 0;
      self.examPaperNumber.radio = 0;
      self.weightRadioNum = 0;
      self.weightCheckboxNum = 0;
      self.weightJudgeNum = 0;
      self.exam_paper_id = 0;
      self.exam_paper_name = '';
    },

    getExamPaperNumber(exam_paper_id) 
    {
      let self = this;
      if (exam_paper_id) 
      {
        let data = {
          exam_paper_id: exam_paper_id
        }
        API.getExamPaperNumber(data)
          .then((result) => {
            if (result.code > 0) 
            {
              console.log(result);
              // self.examPaperNumber = result.data
              self.examList.forEach(function(element) {
                if(element.exam_paper_id == exam_paper_id)
                {
                  element.radio = result.data.radio ?? 0;
                  element.checkbox = result.data.checkbox ?? 0;
                  element.judge = result.data.judge ?? 0;
                }
              })
              console.log(self.examList)
            }
          })
      }
    },

    compare(property) 
    {
      return function (a, b) 
      {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },

    rowDrop() 
    {
      const self = this;
      const tbody = document.querySelector(".sortableTable");
      const sortable = new self.Sortable(tbody, {
        // 监听拖动结束事件;
        onEnd: (evt) => {
          let data = {};
          let sortNo = "";
          let id = "";
          // evt.oldIndex——当前行的被拖拽前的顺序;
          // evt.newIndex——当前行的被拖拽后的顺序;
          if (evt.oldIndex < evt.newIndex)
          {
            // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果;
            window.sessionStorage.setItem("loading", "false");
            if (evt.newIndex == self.projectList2.length - 1) 
            {
              sortNo = self.projectList2[evt.newIndex].sort_no - 1;
              id = self.projectList2[evt.oldIndex].id;
              self.projectList2[evt.oldIndex].sort_no = sortNo;
              // 防止拖拽后从后台获取最新数据页面刷新,影响用户体验,前端处理排序
              self.projectList2 = self.projectList2.sort(
                self.compare("sort_no")
              );
            } 
            else 
            {
              let sortbefore = self.projectList2[evt.newIndex].sort_no - 0;
              let sortnext = self.projectList2[evt.newIndex + 1].sort_no - 0;
              sortNo = (sortbefore + sortnext) / 2;
              id = self.projectList2[evt.oldIndex].id;
              self.projectList2[evt.oldIndex].sort_no = sortNo;
              self.projectList2 = self.projectList2.sort(
                self.compare("sort_no")
              );
            }
            data = {
              id: id,
              sort_no: sortNo,
            };
          } 
          else 
          {
            if (evt.newIndex == 0) 
            {
              sortNo = self.projectList2[evt.newIndex].sort_no - 0 + 1;
              id = self.projectList2[evt.oldIndex].id;
              self.projectList2[evt.oldIndex].sort_no = sortNo;
              self.projectList2 = self.projectList2.sort(
                self.compare("sort_no")
              );
            } 
            else 
            {
              let sortbefore = self.projectList2[evt.newIndex].sort_no - 0;
              let sortnext = self.projectList2[evt.newIndex - 1].sort_no - 0;
              sortNo = (sortbefore + sortnext) / 2;
              id = self.projectList2[evt.oldIndex].id;
              self.projectList2[evt.oldIndex].sort_no = sortNo;
              self.projectList2 = self.projectList2.sort(
                self.compare("sort_no")
              );
            }
            // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果
            window.sessionStorage.setItem("loading", "false");
            data = {
              id: id,
              sort_no: sortNo,
            };
          }
          API.updateSortNo(data)
            .then(() => {
              // self.reload();
              window.sessionStorage.setItem("loading", "true");
            })
        },
      });
    },

    previewExam() 
    {
      var self = this;
      if (self.projectInfo.exam_type == 0) 
      {
        // Message.warning("请设置考试规则！");
        return self.$Tips({
          // 消息提示内容
          message: '请设置考试规则',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()

      let data = {
        project_id: self.project_id
      }
      API.previewExam(data)
        .then((result) => {
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
    },

    handleDetailExplain() 
    {
      window.open(
        process.env.VUE_APP_DOMAIN_URL + "/lims/web/teacher/new_zyxq.html?id=1789"
      );
    },

    handleWeightSelect(weight) 
    {
      var self = this;
      // 当weight=3时选中了期末考试权重
      if (weight == 3) 
      {
        let data = {
          project_id: self.project_id
        }
        API.getEndExamSetting(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.weightData = result.data;
            }
          })
      }
    },

    weightVirtualLevelOK() 
    {
      var self = this;
      let data = {
        project_id: self.project_id,
        cid: self.weightVirtualList,
      }
      // 判断是否是100%；
      let num = 0;
      for (var i = 0; i < self.weightVirtualList.length; i++) 
      {
        num += parseInt(self.weightVirtualList[i].b);
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      API.updateVirtualLevel(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.weightLevelTitle = '关卡配置'
            self.weightLevelDialogVisible = false;
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
    },

    updateoptcoreType(sign) 
    {
      let self = this;
      self.optTypeList.forEach((key) => {
        if (key.name == sign) 
        {
          sign = key.id
        }
      })
      let data = {
        handleContent: self.edithandleContent,
        handleScore: Math.abs(self.edithandleScore),
        project_id: self.project_id,
        id: self.handle_id,
        sign: sign
      }
      if (!self.handleContent && !self.edithandleContent) 
      {
        // return Message.info('请输入操作内容');
        return self.$Tips({
          // 消息提示内容
          message: '请输入操作内容',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      if (!self.handleScore && !self.edithandleScore) 
      {
        return self.$Tips({
          // 消息提示内容
          message: '请输入操作分数',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      API.updateHandle(data)
        .then((result) => {
          if (result.code > 0) {
            self.getHandleList();
            self.handleScore = "";
            self.handleContent = "";
            self.handle_id = '';
            self.weightHandleDialogVisible = false;
            // Message.success(result.msg);
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
    },

    weightHandleDialogOK(sign) 
    {
      var self = this;
      if (sign == '扣分') 
      {
        sign = 0
      }
      else 
      {
        sign = 1
      }
      if (self.weightHandleDialogSign == "edit") 
      {
        self.updateHandle({
          handleContent: self.edithandleContent,
          handleScore: Math.abs(self.edithandleScore),
          project_id: self.project_id,
          id: self.handle_id,
          sign: sign
        });
      } 
      else if (self.weightHandleDialogSign == "delete") 
      {
        let data = {
          id: self.handle_id
        }
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        API.deleteHandle(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.getHandleList();
              self.weightHandleDialogVisible = false;
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
      }
    },

    openHandleDialog(sign, title, row) {
      var self = this;
      self.weightHandleDialogSign = sign;
      self.weightHandleDialogTitle = title;

      self.handle_id = row.id;
      self.opteditSign = sign

      if (sign == "edit") 
      {
        self.edithandleScore = row.score
        self.edithandleContent = row.des
      }
      if (sign == 'delete') 
      {
        self.weightHandleDialogVisible = true;
      }
    },

    updateHandle(param = "") 
    {
      var self = this;
      if (!param) 
      {
        param = {
          handleContent: self.handleContent,
          handleScore: Math.abs(self.handleScore - 0),
          project_id: self.project_id,
          sign: self.optSign,
          id: self.handle_id
        };
      }
      if (!self.handleContent && !self.edithandleContent) 
      {
        // return Message.info('请输入操作内容');
        return self.$Tips({
          // 消息提示内容
          message: '请输入操作内容',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      if (!self.handleScore && !self.edithandleScore) 
      {
        // return Message.info('请输入操作分数')
        return self.$Tips({
          // 消息提示内容
          message: '请输入操作分数',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      API.updateHandle(param)
        .then((result) => {
          if (result.code > 0) 
          {
            self.getHandleList();
            self.handleScore = "";
            self.handleContent = "";
            self.handle_id = '';
            self.weightHandleDialogVisible = false;
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
      // 关闭loading
      nprogressClose();
      self.$Loading.hide()
    },

    getHandleInfo() 
    {
      var self = this;
      let data = {
        id: self.handle_id
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      API.getHandleInfo(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.edithandleScore = result.data.score;
            self.edithandleContent = result.data.des;
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
      // 关闭loading
      nprogressClose();
      self.$Loading.hide()
    },

    weightDialogOK() 
    {
      var self = this;
      if (self.weightDialogSign == "template") 
      {
        let data = {
          report_template_id: typeof (self.update_report_template_id) == 'string' ? self.reporttemplateId : self.update_report_template_id,
          report_put_date: self.reportsubmitallTime ? dateFormat('YYYY-mm-dd HH:MM:SS', new Date(self.reportsubmitallTime)) : '',
          report_put_day: self.reportsubmitlimitTime,
          report_make_up: self.radio2,
          id: self.project_id,
          is_show_report_score: self.is_show_report_score,
        }
        if (self.radio1 == "2" && !self.reportsubmitallTime) 
        {
          return self.$Tips({
            // 消息提示内容
            message: '请选择时间',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
        }
        if (self.radio1 == "3" && !self.reportsubmitlimitTime) 
        {
          return self.$Tips({
            // 消息提示内容
            message: '请输入天数',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
        }
        nprogressStart();
        self.$Loading.show()
        API.updateProjectReportSet(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.weightDialogVisible = false;
              // Message.success(result.msg);
              self.reload()
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
        // 关闭loading
        nprogressClose();
        self.$Loading.hide()
      }
      // ============  LANXINYU  ==================================
      // 设置小程序自测测试规则
      /*else if (self.weightTestType == 7) 
      {
        var str =
          self.weightRadioNum +
          "$$" +
          self.weightCheckboxNum +
          "$$" +
          self.weightJudgeNum +
          "$$" +
          self.weightPackNum;
        console.log(11111111111111);
        let data = {
          throu_score: self.weightPassScore,
          exam_paper_id: typeof (self.exam_paper_name) == 'string' ? self.exam_paper_id : self.exam_paper_name,
          exam_time: self.weightTestTime,
          exam_type: self.weightTestType,
          exam_rule: str,
          project_id: self.project_id,
        }
        if (self.weightRadioNum > self.examPaperNumber.radio || self.weightCheckboxNum > self.examPaperNumber.checkbox || self.weightJudgeNum > self.examPaperNumber.judge) 
        {
          self.$Loading.hide()
          return self.$Tips({
            // 消息提示内容
            message: '题目设置数量不可超过题库总数量',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
        }
        API.updateTestRule(data)
          .then((result) => {
            if (result.code > 0) {
              self.weightDialogVisible = false;
            }
            // 关闭loading

            self.$Loading.hide()
          })
        // 关闭loading
        self.$Loading.hide()
      }//*/
      else if (self.weightDialogSign == "handle") 
      {// 操作
      }
      else if (self.weightDialogSign == "test") 
      {
        var newStr =
          self.isSgin +
          "$$" +
          self.isCheat +
          "$$" +
          self.isCurrentDay +
          "$$" +
          self.isRepeat +
          "$$" +
          self.isCalc +
          "$$" +
          self.isLook;
        let data = {
          throu_score: self.weightPassScore,
          exam_time: self.weightTestTime,
          exam_new_type: newStr,
          examList:self.examList,
          project_id: self.project_id,
          examScoreToOpen: self.examScoreToOpen,
        }
        let listSign = false;
        // 题库列表
        if(self.examList.length > 0)
        {
          self.examList.forEach(function(element){
            if(!element.exam_paper_id || element.exam_paper_id == undefined)
            {
              listSign = true;
              nprogressClose();
              self.$Loading.hide();
              return self.$Tips({
                // 消息提示内容
                message: '请选择试卷！',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 消失时间
                displayTime: 1500
              });
            }
            if(element.weightRadioNum > element.radio || element.weightCheckboxNum > element.checkbox || element.weightJudgeNum > element.judge)
            { 
              listSign = true;
              nprogressClose();
              self.$Loading.hide()
              return self.$Tips({
                // 消息提示内容
                message: '题目设置数量不可超过题库总数量',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button: [
                  {
                    type: 1,
                    text: '知道了'
                  }
                ],
              })
            }
          })
          if(listSign)
          {
            return;
          }
        }
        API.updateTestRule(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.weightDialogVisible = false;
              self.projectInfo.exam_type = self.weightTestType;
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
        // 关闭loading
        nprogressClose();
        self.$Loading.hide()
      }
      else if (self.weightDialogSign == "virtual") 
      {
        if (self.classTime == 1) 
        {
          self.classtimeRander = []
        }
        let data = {
          project_id: self.project_id,
          flushType: self.flushType,
          yuyan: self.yuyan,
          moshi: self.moshi,
          datatype: self.datatype,
          is_open_exp:self.is_open_exp,
          cidSign: JSON.stringify(self.weightVirtualList),
          check_start_time: self.classtimeRander[0] ? dateFormat('YYYY-mm-dd HH:MM', new Date(self.classtimeRander[0])) : '',
          check_end_time: self.classtimeRander[1] ? dateFormat('YYYY-mm-dd HH:MM', new Date(self.classtimeRander[1])) : '',
        }
        if (self.classTime == 2 && self.classtimeRander.length == 0) 
        {
          nprogressClose();
          self.$Loading.hide()
          return self.$Tips({
            // 消息提示内容
            message: '请选择时间',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
        }
        let cidList = 0;
        if (self.weightVirtualList) 
        {
          self.weightVirtualList.forEach((item) => {
            cidList += (item.b - 0)
          })
        }
        API.updateVirtualRule(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.weightDialogVisible = false;
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
        nprogressClose();
        self.$Loading.hide()
      }
    },

    CidChange() {
      var self = this;
      self.cidWeightTotal = 0;
      if (self.weightVirtualList) 
      {
        self.weightVirtualList.forEach((item) => {
          self.cidWeightTotal += (item.b - 0)
        });
      }
    },

    weightDialogOpen(sign, title, weightDialogWidth) 
    {
      var self = this;
      self.weightDialogSign = sign;
      self.weightDialogTitle = title;
      self.weightDialogWidth = weightDialogWidth;
      if (sign == "template") 
      {// 获取报告模板
        self.weightDialogVisible = true;
      }
      else if (sign == "handle") 
      {// 操作
        self.weightDialogVisible = true;
        self.getHandleList();
      }
      else if (sign == "test") 
      {// 考试
        self.weightDialogVisible = true;
        let data = {
          id: self.project_id
        }
        API.getTestRule(data)
          .then((result) => {
            if (result.code > 0) 
            {
              // =============================================================
              // 新版返回试卷试题数量 add by camellia 2023-08-28
              self.examList = result.examList;
              /*self.examList.forEach(function(element){
                element.exam_paper_id = parseInt(element.exam_paper_id);
              })//*/
              self.exam_paper_id = result.data.exam_paper_id;
              self.weightPassScore = result.data.throu_score;
              self.weightTestTime = result.data.exam_time;
              self.weightTestType = result.data.exam_type;
              self.examScoreToOpen = result.data.exam_score_to_open;
              self.examPaperList = result.examPaperList;
              self.examPaperNumber = result.examPaperNumber;
              // 测试规则
              self.isSgin = result.data.isSgin;
              self.isCheat = result.data.isCheat;
              self.isCurrentDay = result.data.isCurrentDay;
              self.isRepeat = result.data.isRepeat;
              self.isCalc = result.data.isCalc;
              self.isLook = result.data.isLook;

              if (self.examPaperList) 
              {
                let exam_paper_name = self.examPaperList.find((item) => {
                  return item.id == self.exam_paper_id
                })
                self.exam_paper_name = exam_paper_name ? exam_paper_name.title : '';
              }
              let str = result.data.exam_rule;
              if (str) 
              {
                let arr = str.split("$$");
                // 测试规则 单选$$多选$$判断$$填空
                self.weightRadioNum = arr[0];
                self.weightCheckboxNum = arr[1];
                self.weightJudgeNum = arr[2];
                self.weightPackNum = arr[3];
              }
              // 新版考试类型
              let newStr = result.data.exam_rule;
              if (str) 
              {
                let arr = str.split("$$");
                // 测试规则 单选$$多选$$判断$$填空
                self.weightRadioNum = arr[0];
                self.weightCheckboxNum = arr[1];
                self.weightJudgeNum = arr[2];
                self.weightPackNum = arr[3];
              }
            }
          })
      }
      else if (sign == "virtual") 
      {// 虚拟
        self.weightVirtualList = [];
        self.recoverRules();
        let data = {
          project_id: self.project_id
        }
        API.getVirtualRule(data)
          .then((result) => {
            if (result.code > 0) 
            {
              if (!result.data.exp_id) 
              {
                self.weightDialogVisible = false;
                // Message.warning("未绑定虚拟项目！");
                self.$Tips({
                  // 消息提示内容
                  message: '未绑定虚拟项目！',
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType: 'warning',
                  // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                  button: [
                    {
                      type: 0,
                      text: '现在前往'
                    },
                    {
                      type: 1,
                      text: '知道了'
                    }
                  ],
                  // 回调函数
                  callBack: () => {
                    self.activeName = 'fourth',
                    self.vistual_exp_id = "";
                    self.vistual_group_id = "";
                    self.vistual_group_list = [];
                    self.vistual_exp_list = [];
                    self.getVistualList();
                  },
                })
                return;
              }
              self.cidWeightTotal = 0;
              result.data.cid?.forEach((item) => {
                item.b = Number(item.b).toFixed(2) - 0;
                self.cidWeightTotal += item.b;
              })
              // 限制时间
              if (result.data.check_start_time || result.data.check_end_time) 
              {
                self.classTime = "2"
                self.classtimeRander = [result.data.check_start_time, result.data.check_end_time]
              }
              self.weightDialogVisible = true;
              // 虚拟考核
              self.weightVirtualTest = result.data.expscoretype;
              // 语言选择
              self.yuyan = result.data.yuyan + '';
              // 自动刷分
              if (result.data.flush_type == "" || result.data.flush_type == "2" || result.data.flush_type == null) {
                self.flushType = result.data.flush_type;
              }
              else 
              {
                self.flushType = "1";
              }
              // 模式选择
              self.moshi = result.data.moshi + '';
              self.is_open_exp = result.data.is_open_exp;
              // 关卡数量
              self.weightVirtualCid = result.data.cidnum;
              // 关卡权重设置列表
              self.weightVirtualList = result.data.cid ?? [];
              // 数据采用
              self.datatype = result.data.datatype ?? "0";
              // if (result.data.cid) {
              //   result.data.cid.forEach((item)=>{
              //     item.checked = true;
              //   })
              //   // 关卡权重列表
              //   self.weightVirtualList = result.data.cid;
              // }
              if (self.weightVirtualCid && self.weightVirtualList.length == 0) 
              {
                self.weightVirtualList = avgScore(self.weightVirtualCid, true)
              }
              if (self.weightVirtualList.length > 0) 
              {
                self.cidType = "2"
              }
            }
          })
      }
    },

    getHandleList() 
    {
      var self = this;
      let data = {
        id: self.project_id
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      API.getHandleList(data)
        .then((result) => {
          if (result.code > 0) 
          {
            result.data.forEach((item) => {
              item.score = item.sign == 0 ? '-' + item.score : Math.abs(item.score)
              self.optTypeList.forEach((key) => {
                if (key.id == item.sign) 
                {
                  item.sign = key.name
                }
              })
            })
            self.handleList = result.data;
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
      nprogressClose();
      self.$Loading.hide()
    },

    jumpToReportEdit(row) 
    {
      window.sessionStorage.setItem('havename', 1);
      window.sessionStorage.setItem('href', window.location.href);
      window.open(
        "#/teacher/report/reportCorrection?project_id=" +
        row.project_id +
        "&report_id=" +
        row.report_id +
        "&group_id=" +
        this.report_group_id +
        "&hideMenu=" + 1 +
        "&open_id=" +
        row.open_id +
        "&name=" + row.name +
        "&type=" + 1
      );
    },

    jumpToReportEditReport(row)
    {
      const self = this;
      window.sessionStorage.setItem('havename', 1);
      window.sessionStorage.setItem('href', window.location.href);
      window.open(
        "#/teacher/report/reportCorrection?project_id=" +
        self.project_id +
        "&report_id=" +
        row.report_id +
        "&group_id=" +
        self.report_group_id +
        "&hideMenu=" + 1 +
        "&open_id=" +
        "&name=" + row.username +
        "&type=" + 1
      );
    },

    handleReportPageChange(val) 
    {
      const self = this;
      self.reportPage = val;
      self.getStudentReportList();
    },

    handleVistualPageChange(val) 
    {
      const self = this;
      self.vistualPage = val;
      self.getVistualList();
    },

    handleMarkPageChange(val) 
    {
      const self = this;
      self.markPage = val;
      self.getStudentMarkList();
    },

    projectTabHandleClick(tab, event) 
    {
      if (tab.name == "one") 
      {
        this.handleSign = "create";
      } 
      else if (tab.name == "two") 
      {
        this.handleSign = "import";
      }
    },

    projectUploadSuccess(res) 
    {
      const self = this;
      if (res.code > 0) 
      {
        self.projectFilePath = res.path;
      }
    },
    /**
     * @name: 上传文件数量校验
     * @param:	file	json	上传的文件对象
     * @param:	fileList	json	现存的文件列表
     */
    projectUploadExceed(files, fileList) 
    {
      let self = this;
      self.$Tips({
        // 消息提示内容
        message: `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`,
        // 消息提示类型（success-成功,warning-警告/失败）
        messageType: 'warning',
        // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
        button: [
          {
            type: 1,
            text: '知道了'
          }
        ],
      })
    },

    projectBeforeUpload(file) 
    {
      const self = this;
      var array = file.name.split(".");
      if (array.length < 2) 
      {
        // Message.error("文件错误！");
        self.$Tips({
          // 消息提示内容
          message: `文件错误`,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        self.fileList = [];
        return;
      }
      var ext = array[array.length - 1];
      if (ext != "xls" && ext != "xlsx") 
      {
        // Message.error("文件格式为xls，xlsx，请重试！");
        self.$Tips({
          // 消息提示内容
          message: `文件格式为xls，xlsx，请重试`,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        self.fileList = [];
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) 
      {
        // Message.error("上传头像图片大小不能超过 5MB!");
        self.$Tips({
          // 消息提示内容
          message: `上传头像图片大小不能超过 5MB`,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        self.fileList = [];
        return;
      }
    },

    projectBeforeRemove(file, fileList) 
    {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    downloadProjectTemplate() 
    {
      const self = this;
      self.dialogVisible = false;
      window.open(process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/project/downloadProjectTemplate");
    },

    reportSelectChange() 
    {
      this.getStudentReportList();
    },

    getStudentReportList() 
    {
      const self = this;
      sleep(1000).then(() => {
        self.updateCssStyle(self.project_id);
      });
      self.report_status_id_list.forEach((item) => {
        if (item.name == self.report_status_id) 
        {
          self.report_status = item.id
        }
      })
      self.$router.push({
        path: "/teacher/projectList",
        query: {
          project_id: self.project_id,
          activeName: self.activeName,
          report_group_id: typeof (self.report_group_name) == 'string' ? self.report_group_id : self.report_group_name,
          report_status_id: self.report_status_id,
          sign: self.class_name,
        },
      });
      if (!self.isShow) 
      {
        return;
      }
      else 
      {
        let data = {
          group_id: typeof (self.report_group_name) == 'string' ? self.report_group_id : self.report_group_name,
          project_id: self.project_id,
          status: self.report_status,
          page: self.reportPage,
          pagesize: self.reportPageSize,
          name: self.report_student_name,
          timeOrderBy: self.reporttimeOrderBy,
          scoreOrderBy: self.reportscoreOrderBy
        };
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        API.getStudentReportList(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.report_group_list = result.groupList;
              self.report_group_id = result.group_id;
              if (result.groupList) 
              {
                result.groupList.forEach((item) => {
                  if (item.id == self.report_group_id)
                    self.report_group_name = item.name;
                })
              }
              self.report_data_list = result.studentList;
              self.reportDataTotal = result.studentNum;
              self.rightShowTotalNum = result.rightShowTotalNum
              self.Reviewed = result.Reviewed;
              self.notReviewed = result.notReviewed;
              self.notReportNum = result.notReportNum;
            }
            else 
            {
              self.report_data_list = [];
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
      }
    },

    vistualDialogExec() {
      if (this.vistual_sign == "flush") 
      {
        this.flushExpScore();
      }
      else if (this.vistual_sign == "unlock") 
      {
        this.unlockTotal();
      }
    },

    vistualDialogOpen(sign) 
    {
      var self = this;
      if (!self.vistual_exp_id) 
      {
        // return Message.info('暂未绑定虚拟实验')
        return self.$Tips({
          // 消息提示内容
          message: `暂未绑定虚拟实验`,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      if (self.messagevirtualCode === -3) 
      {
        return self.$Tips({
          // 消息提示内容
          message: `该班级不能全部解锁`,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      self.vistual_dialog = true;
      self.vistual_sign = sign;
      self.unLockCost = 0;
      let cost = 0;
      if (self.vistual_expinfo.cost != undefined || self.vistual_expinfo.cost != null) 
      {
        cost = self.vistual_expinfo.cost;
      }
      self.unLockCost = self.vistual_nopaycount * cost;
    },

    unlockTotal() 
    {
      const self = this;
      let exp_id = self.vistual_exp_id == self.vistual_exp_name ? self.vistual_exp_id_copy : self.vistual_exp_id
      let data = {
        group_id: self.vistual_group_id,
        project_id: self.project_id,
        exp_id: exp_id,
        cost: self.unLockCost,
      };
      if (!self.vistual_exp_id) 
      {
        return self.$Tips({
          // 消息提示内容
          message: `暂未绑定虚拟实验`,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      API.unlockTotal(data)
        .then((result) => {
          if (result.code > 0) {
            self.getVistualList();
            self.vistual_dialog = false;
          }
          else 
          {
            return self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button: [
                {
                  type: 1,
                  text: '知道了'
                }
              ],
            })
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
      // 关闭loading
      nprogressClose();
      self.$Loading.hide()
    },

    flushExpScore() 
    {
      const self = this;
      // ===================================================================================
      // ADD BY LXY   虚拟刷成绩可应用于所有班级
      let vistual_group_id = [];
      if (self.useScoreAlling) 
      {
        self.vistual_group_list.forEach((item) => {
          vistual_group_id.push(item.id);
        })
        vistual_group_id = vistual_group_id.toString();
      }
      else 
      {
        vistual_group_id = self.vistual_group_id
      }
      // ===================================================================================
      let data = {
        group_id: vistual_group_id,
        project_id: self.project_id,
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      API.flushExpScore(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.getVistualList();
            self.vistual_dialog = false;
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
    },

    bindVistualToExp() {
      const self = this;
      let exp_id = self.vistual_exp_id == self.vistual_exp_name ? self.vistual_exp_id_copy : self.vistual_exp_id
      let data = {
        exp_id: exp_id,
        project_id: self.project_id,
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      API.bindVistualToExp(data)
        .then((result) => {
          if (result.code > 0) {}
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
      nprogressClose();
      self.$Loading.hide()
    },

    vistualGroupSelectChange() 
    {
      this.vistualPage = 1;
      this.getVistualList();
    },

    vistualExpSelectChange() 
    {
      this.getVistualList();
    },

    vistualExpSelectClear() 
    {
      this.vistualRemoteMethod("");
    },

    vistualRemoteMethod(query) 
    {
      const self = this;
      if (query === "") {
        this.options = [];
      }
      let data = {
        name: query,
      };
      self.loading = true;
      API.searchExpList(data)
        .then((result) => {
          if (result.code > 0) {
            self.vistual_exp_list = result.data;
          }
          self.loading = false;
        })
    },

    getVistualList() 
    {
      const self = this;
      let project = self.projectList.find((item) => {
        return item.id == self.project_id
      })
      self.is_create = project.is_create - 0;
      self.vistual_group_list = [];
      // self.vistual_data_list = [];
      self.messagevirtualCode = '';
      self.messagevirtualTip = '';
      // self.vistualDataTotal = 0;
      sleep(1000).then(() => {
        self.updateCssStyle(self.project_id);
      });
      let exp_id = self.vistual_exp_id == self.vistual_exp_name ? self.vistual_exp_id_copy : self.vistual_exp_id;
      if (exp_id == undefined) 
      {
        exp_id = '';
      }
      let data = {
        project_id: self.project_id,
        exp_id: exp_id,
        group_id: self.vistual_group_id,
        page: self.vistualPage,
        pagesize: self.vistualPageSize,
        name: self.vistual_student_name,
        scoreOrderBy: self.scoreOrderBy,
        personalIdOrderBy: self.personalIdOrderBy
      };
      self.$router.push({
        path: "/teacher/projectList",
        query: {
          project_id: self.project_id,
          activeName: self.activeName,
          sign: self.class_name
        },
      });
      if (!self.isShow) {
        return;
      }
      else 
      {
        // 开启loading
        nprogressStart();
        self.$Loading.show();
        // 请求数据
        API.getVistualList(data)
          .then((result) => {
            console.log(result.data)
            self.vistual_data_list = result.data;
            let vistual_exp_id = self.vistual_exp_list?.find((item) => {
              return item.id === result.exp_id;
            })
            self.vistual_exp_id = vistual_exp_id ? vistual_exp_id.id.toString() : result.expName;
            self.vistual_exp_id_copy = result.exp_id;
            self.vistual_exp_name = result.expName;
            if (result.code > 0) {
              if (result.data.length === 0) {
                // self.vistual_exp_list = result.expList;
                this.messagevirtualCode = -3;
                this.messagevirtualTip = '该班级暂无学生信息';
              }
              // else 
              // {
              if (self.vistual_exp_list.length <= 0) {
                self.vistual_exp_list = result.expList;
              }
              if (self.vistual_group_list.length <= 0) {
                self.vistual_group_list = result.groupList;
              }
              if (!self.vistual_group_id) 
              {
                // self.vistual_group_id = result.groupList[0].id;
                self.vistual_group_id = result.group_id;
              }
              let vistual_exp_id = self.vistual_exp_list?.find((item) => {
                return item.id === result.exp_id;
              })
              self.vistual_exp_id = vistual_exp_id ? vistual_exp_id.id.toString() : result.expName;
              self.vistual_exp_id_copy = result.exp_id;
              self.vistual_exp_name = result.expName;
              self.vistual_data_list = result.data;
              // self.vistual_exp_id = result.exp_id;
              self.vistual_nopaycount = result.wdg;
              self.vistual_expinfo = result.expInfo;
              self.user_balance = result.balance;
              self.vistualDataTotal = result.stugdeNum;
              // }
              nprogressClose();
              self.$Loading.hide()
            }
            else if (result.code == -1) {
              nprogressClose();
              self.$Loading.hide()
              this.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button: [
                  {
                    type: 1,
                    text: '知道了'
                  }
                ],
              })
            }
            else if (result.code == -3) {
              nprogressClose();
              self.$Loading.hide();
              this.messagevirtualCode = result.code;
              this.messagevirtualTip = '该班级暂无学生信息';
              return this.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button: [
                  {
                    type: 1,
                    text: '知道了'
                  }
                ],
              })
            }
          })
      }
    },

    markHandleAvatarSuccess(res) 
    {
      const self = this;
      if (res.code > 0) 
      {
        self.markFilePath = res.path;
      }
    },

    /**
     * @name: 上传文件数量校验
     * @param:	file	json	上传的文件对象
     * @param:	fileList	json	现存的文件列表
     */
    handleExceed(files, fileList) 
    {
      let self = this;
      self.$Tips({
        // 消息提示内容
        message: `当前限制选择 1 个文件，本次选择了 ${
          files.length
          } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
        // 消息提示类型（success-成功,warning-警告/失败）
        messageType: 'warning',
        // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
        button: [
          {
            type: 1,
            text: '知道了'
          }
        ],
      })
    },

    markHandleBeforeUpload(file) 
    {
      const self = this;
      var array = file.name.split(".");
      if (array.length < 2) 
      {
        // Message.error("文件错误！");
        self.$Tips({
          // 消息提示内容
          message: '文件错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        self.fileList = [];
        return;
      }
      var ext = array[array.length - 1];
      if (ext != "xls" && ext != "xlsx") 
      {
        // Message.error("文件格式为xls，xlsx，请重试！");
        self.$Tips({
          // 消息提示内容
          message: '文件格式为xls，xlsx，请重试',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        self.fileList = [];
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) 
      {
        // Message.error("上传头像图片大小不能超过 5MB!");
        self.$Tips({
          // 消息提示内容
          message: '上传头像图片大小不能超过 5MB',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        self.fileList = [];
        return;
      }
    },

    beforeRemove(file, fileList) 
    {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    exportStudentListScore() 
    {
      var self = this;
      var url =
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/project/exportStudentListScore?project_id=" +
        self.project_id +
        "&group_id=" +
        self.mark_group_id;
      window.open(url);
    },

    markDialogOpen(sign) 
    {
      const self = this;
      self.markDialogVisible = true;
      self.markDialogSign = sign;
    },

    markDialogExec() 
    {
      const self = this;
      // 开启loading
      if (self.markDialogSign == "import") 
      {
        //导入成绩
        const self = this;
        let data = {
          project_id: self.project_id,
          group_id: self.mark_group_id,
          filePath: self.markFilePath,
        };
        if (!self.markFilePath) 
        {
          return self.$Tips({
            // 消息提示内容
            message: `请上传文件`,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
        }
        nprogressStart();
        self.$Loading.show()
        API.updateStudentScore(data)
          .then((result) => {
            if (result.code > 0) {
              self.markDialogVisible = false;
              self.fileList = [];
              self.markChangeGroup();
              // Message.success(result.msg);
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
          .catch(() => {
            nprogressClose();
            self.$Loading.hide()
          })
      }
      else if (self.markDialogSign == "subscribe") 
      {
        // 催预约
        const self = this;
        let data = {
          project_id: self.project_id,
          group_id: self.mark_group_id,
          filePath: self.markFilePath,
        };
        nprogressStart();
        self.$Loading.show()
        API.urgeAppointment(data)
          .then((result) => {
            self.markDialogVisible = false;
            nprogressClose();
            self.$Loading.hide()
          })
          .catch(() => {
            nprogressClose();
            self.$Loading.hide()
          })
      }
      else if (self.markDialogSign == "report") 
      {
        // 催报告
        const self = this;
        let data = {
          project_id: self.project_id,
          group_id: self.mark_group_id,
          filePath: self.markFilePath,
        };
        nprogressStart();
        self.$Loading.show()
        API.urgeReport(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.markDialogVisible = false;
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch(() => {
            nprogressClose();
            self.$Loading.hide();
          })
      }
      else if (self.markDialogSign == "totalmark") 
      {
        const self = this;
        // 计算总分
        // ===================================================================================
        // ADD BY LXY   计算总分可应用于所有班级

        let mark_group_id = [];
        if (self.useAlling) 
        {
          self.groupList.forEach((item) => {
            mark_group_id.push(item.id);
          })
          mark_group_id = mark_group_id.toString();
        }
        else 
        {
          mark_group_id = self.mark_group_id
        }
        // ===================================================================================
        let data = {
          project_id: self.project_id,
          group_id: mark_group_id,
        };
        nprogressStart();
        self.$Loading.show();
        API.flushTotalScore(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.markDialogVisible = false;
              self.markChangeGroup();
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch(() => {
            nprogressClose();
            self.$Loading.hide();
          })
      }
    },

    downloadImportTemplate() 
    {
      const self = this;
      self.markDialogVisible = false;
      window.open(
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/project/downloadImportTemplate"
      );
    },

    updateWeight() 
    {
      const self = this;
      self.weight_total =
        parseInt(self.weightInfo.xnsy) +
        parseInt(self.weightInfo.yxcy) +
        parseInt(self.weightInfo.sybg) +
        parseInt(self.weightInfo.yxmb) +
        parseInt(self.weightInfo.ktcz);
      if (self.weight_total != 100) 
      {
        // Message.error(" 总权重需 100%，当前总和 " + self.weight_total + "%");
        self.$Tips({
          // 消息提示内容
          message: `总权重须为100%，当前总和${self.weight_total}%`,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
          displayTime: 1500
        })
        return;
      }
      let data = {
        weightInfo: self.weightInfo,
        id: self.project_id,
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      // 请求数据
      API.updateWeight(data)
        .then((result) => {
          if (result.code > 0) {}
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
        .catch(() => {
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
    },

    weightReckon() 
    {
      const self = this;
      self.weight_total =
        parseInt(self.weightInfo.xnsy) +
        parseInt(self.weightInfo.yxcy) +
        parseInt(self.weightInfo.sybg) +
        parseInt(self.weightInfo.yxmb) +
        parseInt(self.weightInfo.ktcz);
    },

    baiduClick(e) 
    {
      this.projectInfo.lon = e.Bg.lng;
      this.projectInfo.lat = e.Bg.lat;
    },

    /**
     * @name: 添加同事远程搜索
     * @param:	query	string	搜索关键词
     */
    remoteMethod(query) 
    {
      const self = this;
      if (query === "") 
      {
        this.options = [];
      }
      let data = {
        name: query,
      };
      self.loading = true;
      API.searchTogether(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.loading = false;
            self.list = result.data;
            self.options = self.list.map((item) => {
              return { value: `${item.id}`, label: `${item.name + item.personal_id}`, };
            });
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
    },

    goToNewLabel(path, param) 
    {
      const { href } = this.$router.resolve({
        path: path,
        query: param,
      });
      window.open(href, "_blank");
    },

    updateProjectSet() 
    {
      const self = this;
      self.projectInfo.isuse = 0;
      if (!self.projectInfo.name) 
      {
        return self.$Tips({
          // 消息提示内容
          message: '请输入项目名称',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      if (self.projectInfo.report_template_id) 
      {
        self.projectInfo.isuse = 1;
      }
      delete self.projectInfo.reportTemplateName
      // ADD BY LANXINYU===============================================================================
      // 处理点击页面右上角保存按钮，测验规则保存失败的问题
      /*var str =
      self.weightRadioNum +
      "$$" +
      self.weightCheckboxNum +
      "$$" +
      self.weightJudgeNum +
      "$$" +
      self.weightPackNum;
      self.projectInfo.exam_rule = str;
      self.projectInfo.exam_time = self.weightTestTime;
      self.projectInfo.throu_score = self.weightPassScore;
      self.projectInfo.exam_type = self.weightTestType;//*/
      // ===============================================================================================
      let data = {
        projectInfo: self.projectInfo,
        id: self.project_id,
      }
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      // 请求数据
      API.updateProjectSet(data)
        .then((result) => {
          if (result.code > 0) 
          {
            for (const item of self.projectList) 
            {
              if (item.id == self.project_id) 
              {
                item.name = self.projectInfo.name;
              }
            }
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
      self.$Loading.hide()
    },

    updateProjectOpenStatus(index) 
    {
      const self = this;
      var isopen = 1;
      if (self.projectList[index].isopen) 
      {
        isopen = 0;
      }
      let data = {
        id: self.projectList[index].id,
        isopen: isopen,
      };
      // 请求数据
      API.updateProjectOpenStatus(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.getProjectList();
          }
        })
    },

    classChangeOption() 
    {
      this.getProjectList();
    },

    classClearOption() 
    {
      this.getProjectList();
    },

    sortNoUpdate(index) 
    {
      const self = this;
      let data = {
        id: self.projectList[index].id,
        sort_no: self.projectList[index].sort_no,
      };
      // 请求数据
      API.updateSortNo(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.getProjectList();
          }
        })
    },

    handleAvatarSuccess(res) 
    {
      const self = this;
      if (res.code > 0) 
      {
        self.projectInfo.pic = res.data;
        // Message.success(res.msg);
      }
    },

    beforeAvatarUpload(file) 
    {
      let self = this;
      const isJPG =
        file.type === "image/jpeg" ||
        "image/png" ||
        "image/jpg" ||
        "image/gif" ||
        "image/bmp";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) 
      {
        // Message.success("上传头像图片只能是 JPG/png/gif/bmp 格式!");
        self.$Tips({
          // 消息提示内容
          message: '上传头像图片只能是 JPG/png/gif/bmp 格式',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      if (!isLt2M) 
      {
        // Message.error("上传头像图片大小不能超过 5MB!");
        self.$Tips({
          // 消息提示内容
          message: '上传头像图片大小不能超过 5MB',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      return isJPG && isLt2M;
    },

    deleteClassShow() 
    {
      const self = this;
      self.deleteClassShowSign = !self.deleteClassShowSign;
    },

    updateCssStyle(id) {
      // css效果
      var d = document.querySelectorAll(".left-module tr");
      for (var p = d.length; p--;) {
        if (d[p].id != id) {
          d[p].style.backgroundColor = "#FFFFFF"; /*其他*/
          d[p].classList.remove("selectTr");
        } else {
          d[p].style.backgroundColor = "#E5ECF1"; /*点击的*/
          d[p].classList.add("selectTr");
        }
      }
    },

    hideProject(id, index, sign = "", status = true, name) 
    {
      let self = this;
      self.isShow = false;
      self.isRecover = true;
      sleep(1000).then(() => {
        self.updateCssStyle(id);
      });
      // self.projectIndex = index;
      // self.project_id = self.projectList[index].id;
      self.mark_group_id = '';
      self.rightContentShow = false;
      self.project_id = id;
      if (sign == "delete") 
      {
        self.projectName = self.deleteProjectList[index].name;
        self.rightContentShow = false;
      }
      self.activeName = 'first';
      // 开启loading
      // self.$Loading.show()
      self.$router.push({
        path: "/teacher/projectList",
        query: {
          project_id: self.project_id,
          activeName: self.activeName,
          sign: self.class_name
        },
      });
      return;
    },

    getProjectInfo(id, index, sign = "", status = true) 
    {
      // 请求数据
      const self = this;
      if (!status) 
      {
        self.isShow = false;
        sleep(1000).then(() => {
          self.updateCssStyle(id);
        });
        // self.projectIndex = index;
        // self.project_id = self.projectList[index].id;
        self.mark_group_id = '';
        self.rightContentShow = false;
        self.project_id = id;
        if (sign == "delete") 
        {
          self.projectName = self.deleteProjectList[index].name;
          self.rightContentShow = false;
        }
        self.activeName = 'first';
        // 开启loading
        // self.$Loading.show()
        self.$router.push({
          path: "/teacher/projectList",
          query: {
            project_id: self.project_id,
            activeName: self.activeName,
            sign: self.class_name
          },
        });
        return;
      }
      else 
      {
        sleep(1000).then(() => {
          self.updateCssStyle(id);
        });
        // self.projectIndex = index;
        // self.project_id = self.projectList[index].id;
        self.mark_group_id = '';
        self.rightContentShow = true;
        self.project_id = id;
        if (sign == "delete") 
        {
          self.projectName = self.deleteProjectList[index].name;
          self.rightContentShow = false;
        }
        self.activeName = 'first';
        self.$router.push({
          path: "/teacher/projectList",
          query: {
            project_id: self.project_id,
            activeName: self.activeName,
            sign: self.class_name
          },
        });
        self.consumables = [
          {
            name:"",//名称
            num:"",// 数量
            unit:"",// 单位
            id:0,// id
          }
        ];
        let data = {
          id: self.project_id,
        };
        nprogressStart();
        self.$Loading.show();
        // 请求数据
        API.getProjectInfo(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.projectCourseList = result.projectCourseList
              self.is_create = result.is_create;
              localStorage.setItem('is_create', self.is_create)
              self.createUserName = result.createUserName;
              self.is_show_bind = result.is_show_bind;
              self.projectInfo = result.projectInfo;
              if(self.projectInfo && self.projectInfo.consumables)
              {
                self.consumables = JSON.parse(self.projectInfo.consumables);
              }
              self.articleList = result.articleList;
              self.togetherList = result.togetherList;
              self.reportTemplateList = result.reportTemplateList;
              const reportTemplate = result.reportTemplateList?.find((item) => {
                return item.id == result.projectInfo.report_template_id
              })
              if (!reportTemplate) 
              {
                self.reportTemplateList.push({
                  id: result.projectInfo.report_template_id,
                  name: result.reportTemplateName
                })
              }
              const article = result.articleList?.find((item) => {
                return item.id == result.projectInfo.article_id
              })
              if (!article) 
              {
                self.articleList.push({
                  id: result.projectInfo.article_id,
                  title: result.articleName
                })
              }
              const togther = result.togetherList?.find((item) => {
                return item.id == result.projectInfo.salve_uid
              })
              if (!togther) 
              {
                self.togetherList.push({
                  id: result.projectInfo.salve_uid,
                  name: result.togtherName
                })
              }

              self.examPaperList = result.examPaperList;
              if (self.activeName == '') 
              {
                self.activeName = "first";
              }
              if (self.projectInfo != null) 
              {
                if (self.projectInfo.lon > 0 && self.projectInfo.lat > 0) 
                {
                  self.baiduCenter = {
                    lng: self.projectInfo.lon,
                    lat: self.projectInfo.lat,
                  };
                }
                if (!self.projectInfo.salve_uid) 
                {
                  self.projectInfo.salve_uid = self.togetherList[0].id;
                }
              }
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
        // 关闭loading
        self.$Loading.hide()
      }

    },

    jumpToGroup(course_id) 
    {
      window.open("#/teacher/course?course_id=" + course_id + '&activeName=' + 'first' + '&sign=' + '-3')
    },

    tabHandleClick(tab, event) 
    {
      const self = this;
      self.activeName = tab.name;
      if (self.projectList.length > 0) 
      {
        if (tab.name == "first") 
        {
          if (!self.isRecover) 
          {
            self.getProjectInfo(self.project_id, 0);
          }
        }
        else if (tab.name == "second") 
        {
          self.getProjectWeight();
        }
        else if (tab.name == "third") 
        {
          self.studentList = [];
          self.getStudentMarkList();
        }
        else if (tab.name == "fourth") 
        {
          self.vistual_exp_id = "";
          self.vistual_group_id = "";
          self.vistual_group_list = [];
          self.vistual_exp_list = [];
          self.getVistualList();
        }
        else if (tab.name == "fifth") 
        {
          self.report_group_id = 0;
          self.report_group_list = [];
          self.report_data_list = [];
          self.getStudentReportList();
        }
      }
    },

    getStudentMarkList() 
    {
      const self = this;
      self.markDataTotal = 0;
      sleep(1000).then(() => {
        self.updateCssStyle(self.project_id);
      });
      let data = {
        pagesize: self.markPageSize,
        page: self.markPage,
        project_id: self.project_id,
        group_id: self.mark_group_id,
        name: self.mark_student_name,
        personalIdOrderBy: self.scorepersonalIdOrderBy,
        examScoreOrderBy: self.scoreexamScoreOrderBy,
        expScoreOrderBy: self.scoreexpScoreOrderBy,
        optScoreOrderBy: self.scoreoptScoreOrderBy,
        reportScoreOrderBy: self.scorereportScoreOrderBy,
        finalScoreOrderBy: self.scorefinalScoreOrderBy,
      };

      self.$router.push({
        path: "/teacher/projectList",
        query: {
          project_id: self.project_id,
          activeName: self.activeName,
          sign: self.class_name
        },
      });
      if (!self.isShow) 
      {
        return;
      }
      else 
      {
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        // 请求数据
        API.getStudentMarkList(data)
          .then((result) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
            this.messageTip = result.msg ? result.msg : '该班级暂无学生'
            this.messageCode = result.code
            if (result.code > 0) 
            {
              self.groupList = result.groupList ? result.groupList : result.data;
              self.studentList = result.studentList ? result.studentList : [];
              self.noSubscribeNum = result.weiyuyue;
              self.noReportSubmitNum = result.weijiaoreport
              if (!self.mark_group_id) 
              {
                self.mark_group_id = result.mark_group_id;
              }
              // self.mark_group_id = result.mark_group_id;
              self.expscoretype = result.expscoretype;
              self.markDataTotal = result.grpdetailNum ? result.grpdetailNum : 0;
              if (self.expscoretype == 0) 
              {
                self.expscoreString = "默认计分法";
              }
              else if (self.expscoretype == 1) 
              {
                self.expscoreString = "高分均值法(课上时间)";
              }
              else if (self.expscoretype == 2) 
              {
                self.expscoreString = "各关都做法";
              }
              else if (self.expscoretype == 3) 
              {
                self.expscoreString = "指定关卡";
              }
              let index = self.studentList?.findIndex((item) => {
                return item.name == "未注册"
              })
              if (index >= 0) 
              {
                this.$Tips({
                  // 消息提示内容
                  message: '班级成员尚未全部注册',
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType: 'warning',
                  // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                  button: [
                    {
                      type: 1,
                      text: '知道了'
                    }
                  ],
                })
              }
            }
            else if (result.code == -1) 
            {
              this.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button: [
                  {
                    type: 0,
                    text: '现在前往'
                  },
                  {
                    type: 1,
                    text: '知道了'
                  }
                ],
                callBack: () => {
                  self.gocourseProject();
                }
              })
            }
            else 
            {
              this.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button: [
                  {
                    type: 1,
                    text: '知道了'
                  }
                ],
              })
            }
          })
        // 关闭loading     
        nprogressClose();
        self.$Loading.hide()
      }
    },

    getProjectWeight() 
    {
      const self = this;
      let data = {
        id: self.project_id,
      };
      self.update_report_template_id = ''
      sleep(1000).then(() => {
        self.updateCssStyle(self.project_id);
      });

      self.$router.push({
        path: "/teacher/projectList",
        query: {
          project_id: self.project_id,
          activeName: self.activeName,
          sign: self.class_name
        },
      });
      if (!self.isShow) 
      {
        return;
      }
      else 
      {
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        // 请求数据
        API.getProjectWeight(data)
          .then((result) => {
            if (result.code > 0) {
              self.is_create = result.is_create
              self.weightInfo = result.data;
              self.reportTemplateList = result.reportTemplateList;
              // 处理页面报告模板显示为id
              result.reportTemplateList.forEach((item) => {
                if (item.id == result.report_template_id) {
                  self.update_report_template_id = item.name
                }
              })
              self.radio2 = result.report_make_up

              if (result.report_put_day == -1 && result.report_put_date) 
              {
                self.radio1 = "2"
                self.reportsubmitlimitTime = ''
                self.reportsubmitallTime = result.report_put_date
              }
              else if (result.report_put_day != -1 && !result.report_put_date) 
              {
                self.radio1 = "3"
                self.reportsubmitallTime = ''
                self.reportsubmitlimitTime = result.report_put_day
              }
              else 
              {
                self.reportsubmitallTime = ''
                self.reportsubmitlimitTime = ''
                self.radio1 = "1"
              }
              self.is_show_report_score = result.is_show_report_score;
              // self.update_report_template_id
              // 保存报告模板id
              self.reporttemplateId = result.report_template_id;
              // self.RecordRation=result.data.data;
              self.weight_total =
                parseInt(self.weightInfo.xnsy) +
                parseInt(self.weightInfo.yxcy) +
                parseInt(self.weightInfo.sybg) +
                parseInt(self.weightInfo.yxmb) +
                parseInt(self.weightInfo.ktcz);
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
        self.$Loading.hide()
      }

    },

    getProjectListSearch() 
    {
      let self = this;
      let data = {
        class_id: typeof (self.class_id) == 'string' ? self.class_name : self.class_id,
        search: self.project_name
      };
      // 请求数据
      API.getProjectList(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.shareTogetherList = result.togetherList;
            self.deleteProjectList = result.deleteList;
            self.projectList = result.list;
            self.createexpList = result.expList;
            // 深拷贝项目列表数据
            self.projectList2 = JSON.parse(JSON.stringify(self.projectList));
            self.deleteNum = result.deleteNum;
            self.classList = result.classList;
            if (self.activeName == 'first' || self.activeName == '' || self.activeName == undefined || self.activeName == 0) 
            {
              self.activeName = 'first';
              // 请求项目信息
              if (self.project_id) 
              {
                self.getProjectInfo(self.project_id, 0);
              }
              else 
              {
                self.getProjectInfo(self.projectList[0].id, 0);
              }
            }
            else if (self.activeName == "second")
            {
              self.getProjectWeight();
            }
            else if (self.activeName == "third") 
            {
              self.getStudentMarkList();
            }
            else if (self.activeName == "fourth") 
            {
              self.getVistualList();
            }
            else if (self.activeName == "fifth") 
            {
              self.getStudentReportList();
            }
          }
          nprogressClose();
          // 关闭loading
          self.$Loading.hide();
        })
    },

    getProjectList(e) 
    {
      const self = this;
      if (e || e == 0) 
      {
        self.class_name = e
      }
      let data = {
        class_id: typeof (self.class_id) == 'string' ? self.class_name : self.class_id,
        search: self.project_name
      };
      // 请求数据
      API.getProjectList(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.deleteProjectList = result.deleteList;
            self.shareTogetherList = result.togetherList;
            self.projectList = result.list;
            self.createexpList = result.expList;
            // 深拷贝项目列表数据
            self.projectList2 = JSON.parse(JSON.stringify(self.projectList));
            self.deleteNum = result.deleteNum;
            self.classList = result.classList;
            if (self.activeName == 'first' || self.activeName == '' || self.activeName == undefined || self.activeName == 0) 
            {
              self.activeName = 'first';
              // 请求项目信息
              if (self.project_id) 
              {
                self.getProjectInfo(self.project_id, 0);
              }
              else 
              {
                self.getProjectInfo(self.projectList[0].id, 0);
              }
            }
            else if (self.activeName == "second") 
            {
              self.getProjectWeight();
            }
            else if (self.activeName == "third") 
            {
              self.getStudentMarkList();
            }
            else if (self.activeName == "fourth") 
            {
              self.getVistualList();
            }
            else if (self.activeName == "fifth") 
            {
              self.getStudentReportList();
            }
          }
          else
          {
            self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 消失时间
              displayTime:1500
            })
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
    },

    handleOpen(sign, title = "", index = "", name = "") 
    {
      const self = this;
      self.handleSign = sign;
      self.dialogTitle = title;
      self.createexpID = "";
      // 删除
      if (sign == "delete") 
      {
        self.project_id = index;
        self.projectName = name;
      }
      else if (sign == "create" || sign == "rename") 
      {
        self.addProjectActive = "one";
        if (sign == "create") 
        {
          let data = {};
          // 关闭loading
          nprogressStart();
          self.$Loading.show();
          // 请求数据
          self.axios
            .post("/index.php/project/getExpList", data)
            .then((result) => {
              self.createexpList = result.data.list;
              // 关闭loading
              nprogressClose();
              self.$Loading.hide();
            })
            .catch((err) => {
              baiduSearch(err);
            });
        }
        // 创建和重命名
        if (sign == "rename") 
        {
          self.project_id = index;
          self.projectName = name;
        }
      }
      else if (sign == "recover" || sign == "delete_completely") 
      {
        // 恢复或彻底删除
        self.project_id_complete = index;
        self.projectName_complete = name;
        if (name) 
        {
          self.projectName = name;
          self.project_id = index;
        }
      }
      else if (sign == "add_together") 
      {
        self.togetherListSearch = [];
        self.together_id = "";
      }
      self.dialogVisible = true;
    },

    dialogFix() 
    {
      const self = this;
      let data = {
        projectName: self.projectName,
        project_id: self.project_id,
        sign: self.handleSign,
      };
      // 删除
      if (self.handleSign == "delete") 
      {
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        // 请求数据
        API.deleteProject(data)
          .then((result) => {
            if (result.code > 0) {
              self.getProjectList();
              self.dialogVisible = false;
              // Message.success(result.msg);
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
        // 关闭loading  
        self.$Loading.hide()
      }
      else if (
        self.handleSign == "recover" ||
        self.handleSign == "delete_completely"
      ) 
      {
        let date = {
          projectName: self.projectName_complete ? self.projectName_complete : self.projectName,
          project_id: self.project_id_complete ? self.project_id_complete : self.project_id,
          sign: self.handleSign,
        }
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        // 恢复和彻底删除
        API.recoverOrDeleteProject(date)
          .then((result) => {
            if (result.code > 0) {
              self.getProjectList();
              self.dialogVisible = false;
            }
            // 关闭loading
          nprogressClose();
            self.$Loading.hide()
          })
        nprogressClose();
        self.$Loading.hide()
      }
      else if (self.handleSign == "rename") 
      {
        // 重命名
        // 请求数据
        API.updateProjectName(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.getProjectList();
              self.dialogVisible = false;
              self.title = "";
              self.project_id = "";
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
      }
      else if (self.handleSign == "create")
      {
        // 创建
        let param = {
          projectName: self.projectName,
          exp_id: self.createexpID
        };
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        API.updateProjectName(param)
          .then((result) => {
            if (result.code > 0) 
            {
              self.getProjectList();
              self.dialogVisible = false;
              self.projectName = '';
              // Message.success(result.msg);
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
        self.$Loading.hide()
      }
      else if (self.handleSign == "add_together") 
      {
        //添加同事
        let data = {
          recip_uid: self.together_id,
        };
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        API.addTogether(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.getTogetherList();
              // Message.success(result.msg);
              self.dialogVisible = false;
            }
            else 
            {
              self.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button: [
                  {
                    type: 1,
                    text: '知道了'
                  }
                ],
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
        self.$Loading.hide()
      }
      else if (self.handleSign == "import") 
      {
        //导入成绩
        const self = this;
        let data = {
          filePath: self.projectFilePath,
        };
        // 开启loading
        nprogressStart();
        self.$Loading.show()
        API.importProject(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.dialogVisible = false;
              self.getProjectList();
              self.projectFileList = [];
              // Message.success(result.msg);
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide()
          })
        // 关闭loading
        self.$Loading.hide()
      }
    },

    getTogetherList() 
    {
      const self = this;
      let data = {};
      API.getTogetherListEsec(data)
        .then((result) => {
          if (result.code > 0) 
          {
            self.togetherList = result.data;
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
    },

    markChangeGroup() 
    {
      const self = this;
      self.markPage = 1
      let data = {
        project_id: self.project_id,
        group_id: self.mark_group_id,
        page: 1,
        pagesize: self.markPageSize,
        personalIdOrderBy: self.scorepersonalIdOrderBy,
        examScoreOrderBy: self.scoreexamScoreOrderBy,
        expScoreOrderBy: self.scoreexpScoreOrderBy,
        optScoreOrderBy: self.scoreoptScoreOrderBy,
        reportScoreOrderBy: self.scorereportScoreOrderBy,
        finalScoreOrderBy: self.scorefinalScoreOrderBy,
      };
      nprogressStart();
      self.$Loading.show();
      // 请求数据
      API.getMarkListByGroupId(data)
        .then((result) => {
          self.$Loading.hide();
          if (result.code > 0) {
            self.studentList = result.info;
            self.noReportSubmitNum = result.weijiaoreport;
            self.noSubscribeNum = result.weiyuyue;
            self.expscoretype = result.expscoretype;
            self.markDataTotal = result.grpdetailNum;
            if (self.expscoretype == 0) 
            {
              self.expscoreString = "默认计分法";
            }
            else if (self.expscoretype == 1) 
            {
              self.expscoreString = "高分均值法(课上时间)";
            }
            else if (self.expscoretype == 2) 
            {
              self.expscoreString = "各关都做法";
            }
            else if (self.expscoretype == 3) 
            {
              self.expscoreString = "指定关卡";
            }
            let index = self.studentList.findIndex((item) => 
            {
              return item.name == "未注册"
            })
            if (index >= 0) 
            {
              this.$Tips({
                // 消息提示内容
                message: '班级成员尚未全部注册',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                button: [
                  {
                    type: 1,
                    text: '知道了'
                  }
                ],
              })
            }
          }
        })
    },

    goToArticleEdit(path, param) 
    {
      const { href } = this.$router.resolve({
        path: path,
        query: param,
      });
      window.open(href, "_blank");
    },

    goToEditModel() 
    {
      let self = this;
      var templateName = "";
      for (let item of self.reportTemplateList) 
      {
        if (item.id == self.projectInfo.report_template_id) 
        {
          templateName = item.name;
        }
      }
      let { href } = self.$router.resolve({
        path: "/teacher/reportTemplateUpdate",
        query: {
          bank_id: self.projectInfo.report_template_id,
          type: 1,
          hideMenu: 1,
          name: templateName,
          back: 1
        },
      });
      // let href = `/teacher/reportTemplateUpdate?bank_id=${self.projectInfo.report_template_id}&reportTitle=${templateName}`
      window.open(href, "_blank");
    },

    goToPreviewModel() 
    {
      let self = this;
      var templateInfo = {};
      for (let item of self.reportTemplateList) 
      {
        if (item.id == self.projectInfo.report_template_id) 
        {
          templateInfo = item;
        }
      };
      window.sessionStorage.setItem("content", templateInfo.content);
      window.open("#/teacher/report/preview?name=" + templateInfo.name);
    },

    /**
     * @name: 去学情分析页
     */
    openAnalysisPage() 
    {
      let href = "#/teacher/groupList?group_id="+this.vistual_group_id+"&activeName=fifth&exp_id="+this.vistual_exp_id_copy+"&census=1";
      window.open(href, "_blank");
    },

    shouScoreDialog()
    {
      // 获取当前项目的班级信息
      this.getStudentReportList()
      // 控制成绩分析弹窗显示
      this.projectCourseDialogVisible = true;
      // 获取项目成绩
      this.getProjectCourseInfo()
      // 开启loading
      nprogressStart();
      this.$Loading.show()
      // 渲染echart柱状图
      setTimeout(()=>{
          this.projectHis()
          this.projectRad()
            // 关闭loading
            nprogressClose();
            this.$Loading.hide()
      },1000)
    },

    getProjectCourseInfo()
    {
      API.getProjectCourseInfo({group_id:this.mark_group_id ,project_id:this.project_id})
      .then(res =>{
        // console.log(res);
        this.projectTotal = res.total;
        this.projectExp = res.exp;
        this.projectReport = res.report;
        this.projectOpt = res.opt;
        this.projectTest = res.test;
        this.projectAvgTotal = res.avgTotal;
        this.groupName=res.groupName;
        this.projectNameTitle = res.projectName;
      })
    },

    /**
     * @name: 项目成绩柱状图
     */
    projectHis() 
    {
      this.projectHistogram = echarts.init(document.getElementById('projectHistogram'));
        let option ={
          title: {
            text: '总分分析直方图',
            left:'center'
          },
          xAxis: {
            data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
            name:'分数段(%)'
          },
          yAxis: {
            type: 'value',
            name:'人数',
          },
          legend: {
            bottom: '5%'
          },
          grid: {
            right: '70',
            containLabel: true
        },
          series: [
            {
              name:'人数',
              emphasis: {
                focus: 'series'
              },
              zlevel: 2,
              data: [this.projectTotal.ten_nine_number,this.projectTotal.nine_eight_number,this.projectTotal.eight_seven_number,this.projectTotal.seven_six_number,this.projectTotal.zero_six_number],
              type: 'bar',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              },
              label: {
                show: true,
                position: 'top'
              },
              itemStyle: 
              {
                normal: 
                {
                  //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                  barBorderRadius: [10, 10, 10, 10],
                  color:'#3E95B5',
                  opacity:.9,
                },
              }, 
              barWidth: 20,
            },
            {
              name:"曲线",
              emphasis: 
              {
                focus: 'series'
              },
              zlevel: 1,
              data: [this.projectTotal.ten_nine_number,this.projectTotal.nine_eight_number,this.projectTotal.eight_seven_number,this.projectTotal.seven_six_number,this.projectTotal.zero_six_number] ,
              type:'line',
              smooth: true,
              lineStyle:{
                color:'#0D5570'
              },
              showSymbol: false,//去除圆点
              areaStyle: {},
              areaStyle: {
                color: '#C4DFE9'
              }
            }
          ],
        }
        option && this.projectHistogram.setOption(option);
        // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
        window.addEventListener("resize", () => {
          // 执行echarts自带的resize方法，让echarts图表自适应
          this.projectHistogram.resize();
        })
    },

    projectRad() 
    {
      this.projectRadar = echarts.init(document.getElementById('projectRadar'));
      let option = {
        title: {
          text: '总分分析雷达图',
          left:'center'
        },
        legend: {
          bottom: '0'
        },
        tooltip: {
          trigger: 'item'
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '虚拟', max: 100 },
            { name: '测试', max: 100 },
            { name: '操作', max: 100 },
            { name: '报告', max: 100 },
            { name: '总分', max: 100 },
          ]
        },
        series: [
          {
            
            type: 'radar',
            data: [
              {
                value: [this.projectAvgTotal.exp, this.projectAvgTotal.test , this.projectAvgTotal.opt, this.projectAvgTotal.report, this.projectAvgTotal.total],
                name: '平均分/满分',
                areaStyle: {
                  color: '#C3DFED'
                }
              },
            ]
          }
        ]
      };

      option && this.projectRadar.setOption(option);
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
        // 执行echarts自带的resize方法，让echarts图表自适应
        this.projectRadar.resize();
      })
      
    }, 

    /**
     * @name: 项目虚拟化成绩柱状图
     */
    projectExpHis() {
      // 先获取Dom实例
      // let projectExp = echarts.getInstanceByDom(document.getElementById('projectExp'))
      // // 判断实例是否存在 如果不存在就创建新的实例
      // if(projectExp == null) {
      //   projectExp = echarts.init(document.getElementById('projectExp'));
      // }

      this.projectExpEcharts = echarts.getInstanceByDom(document.getElementById('projectExp'))
      if(this.projectExpEcharts == null) {
        this.projectExpEcharts = echarts.init(document.getElementById('projectExp'));
      }

      let option ={
        title: {
          text: '总分分布统计图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectExp.ten_nine_number,this.projectExp.nine_eight_number,this.projectExp.eight_seven_number,this.projectExp.seven_six_number,this.projectExp.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectExp.ten_nine_number,this.projectExp.nine_eight_number,this.projectExp.eight_seven_number,this.projectExp.seven_six_number,this.projectExp.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectExpEcharts.setOption(option);
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
      // 执行echarts自带的resize方法，让echarts图表自适应
      this.projectExpEcharts.resize();
      })
    },

    projectExpShow() 
    {
      this.projectShow = '2'
      // 第一次打开的时候开启loading
      if(this.projectExpEcharts == null) 
      {
        // 开启loading
        nprogressStart();
        this.$Loading.show()
        this.loadingFlag = true;
      }
      // 调用项目虚拟成绩的方法
      setTimeout(()=>{
          this.projectExpHis()
          if(this.loadingFlag) 
          {
            // 关闭loading
            nprogressClose();
            this.$Loading.hide();
            this.loadingFlag = false;
          }
      },500)
    },

    projectTotalShow() 
    {
      this.projectShow = '1'
        // 开启loading
        nprogressStart();
        this.$Loading.show()
      // 延迟执行
      setTimeout(()=>{
        // 关闭loading
        nprogressClose();
        this.$Loading.hide()
        this.projectHis()
        this.projectRad()
      },500)
    },

    /**
     * @name: 项目成绩弹窗关闭的时候触发的方法
     */
    onProjectClose() {
      this.projectShow = '1'
    },

    /**
     * @name: 项目测试成绩图标显示
     */
    projectTestShow() 
    {
      this.projectShow = '3'
      // 第一次打开的时候开启loading
      if(this.projectTestEcharts == null) 
      {
        // 开启loading
        nprogressStart();
        this.$Loading.show()
        this.loadingFlag = true;
      }
      setTimeout(()=>{
      // // 先获取Dom实例
      // let projectTest = echarts.getInstanceByDom(document.getElementById('projectTest'))
      // // 判断实例是否存在 如果不存在就创建新的实例
      // if(projectTest == null) {
      //   projectTest = echarts.init(document.getElementById('projectTest'));
      // }
      this.projectTestEcharts = echarts.getInstanceByDom(document.getElementById('projectTest'))
      // 判断实例是否存在 如果不存在就创建新的实例
      if(this.projectTestEcharts == null) 
      {
        this.projectTestEcharts = echarts.init(document.getElementById('projectTest'));
      }
        // let projectTest = echarts.init(document.getElementById('projectTest'));
      let option ={
        title: {
          text: '总分分布统计图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectTest.ten_nine_number,this.projectTest.nine_eight_number,this.projectTest.eight_seven_number,this.projectTest.seven_six_number,this.projectTest.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectTest.ten_nine_number,this.projectTest.nine_eight_number,this.projectTest.eight_seven_number,this.projectTest.seven_six_number,this.projectTest.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectTestEcharts.setOption(option);
      if(this.loadingFlag)
      {
        // 关闭loading
        nprogressClose();
        this.$Loading.hide()
        this.loadingFlag = false;
      }
    },500)
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
      // 执行echarts自带的resize方法，让echarts图表自适应
      this.projectTestEcharts.resize();
    })
    },

    /**
     * @name: 项目操作成绩
     */
    projectOptShow() 
    {
      this.projectShow = '4'
      // 第一次打开的时候开启loading
      if(this.projectOptEcharts == null) 
      {
        // 开启loading
        nprogressStart();
        this.$Loading.show()
        this.loadingFlag = true
      }
      setTimeout(()=>{
        // 先获取Dom实例
        this.projectOptEcharts = echarts.getInstanceByDom(document.getElementById('projectOpt'))
        // 判断实例是否存在 如果不存在就创建新的实例
        if(this.projectOptEcharts == null) 
        {
          this.projectOptEcharts = echarts.init(document.getElementById('projectOpt'));
        }
      let option ={
        title: {
          text: '总分分布统计图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectOpt.ten_nine_number,this.projectOpt.nine_eight_number,this.projectOpt.eight_seven_number,this.projectOpt.seven_six_number,this.projectOpt.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectOpt.ten_nine_number,this.projectOpt.nine_eight_number,this.projectOpt.eight_seven_number,this.projectOpt.seven_six_number,this.projectOpt.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectOptEcharts.setOption(option);
      if(this.loadingFlag) {
        // 关闭loading
        nprogressClose();
        this.$Loading.hide()
        this.loadingFlag = false
      }
    },500)
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
        // 执行echarts自带的resize方法，让echarts图表自适应
        this.projectOptEcharts.resize();
      })
    },

    projectReportShow() 
    {
      this.projectShow = '5'
      // 第一次打开的时候开启loading
      if(this.projectReportEcharts == null) 
      {
        // 开启loading
        nprogressStart();
        this.$Loading.show()
        this.loadingFlag = true;
      }
      setTimeout(()=>{
      // // 先获取Dom实例
      // let projectReport = echarts.getInstanceByDom(document.getElementById('projectReport'))
      // // 判断实例是否存在 如果不存在就创建新的实例
      // if(projectReport == null) {
      //   projectReport = echarts.init(document.getElementById('projectReport'));
      // }

        // 先获取Dom实例
        this.projectReportEcharts = echarts.getInstanceByDom(document.getElementById('projectReport'))
        // 判断实例是否存在 如果不存在就创建新的实例
        if(this.projectReportEcharts == null) 
        {
          this.projectReportEcharts = echarts.init(document.getElementById('projectReport'));
        }
        // let projectReport = echarts.init(document.getElementById('projectReport'));
      let option = {
        title: {
          text: '总分分布统计图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectReport.ten_nine_number,this.projectReport.nine_eight_number,this.projectReport.eight_seven_number,this.projectReport.seven_six_number,this.projectReport.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectReport.ten_nine_number,this.projectReport.nine_eight_number,this.projectReport.eight_seven_number,this.projectReport.seven_six_number,this.projectReport.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectReportEcharts.setOption(option);
      if(this.loadingFlag) {
         // 关闭loading
        nprogressClose();
        this.$Loading.hide()  
        this.loadingFlag = false
      }
    },500)
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
        // 执行echarts自带的resize方法，让echarts图表自适应
        this.projectReportEcharts.resize();
      })
    }
  },

  // 组件销毁之前的生命周期函数
  beforeDestroy() 
  {
    /**
     * @name: 页面组件销毁的时候,移除绑定的监听resize事件
     * @author: wyf
     * @data: 2023-07-03
     */
    window.removeEventListener("resize", () => {
      // this.projectExpEcharts.resize();
      this.projectHistogram.resize();
      this.projectRadar.resize();
    });
  },

};




































































